import React from "react";
import "./index.style.css";
import moment from "moment";
import Day from "./Day";
import { IEvent } from "../../types/Event";
import { IParent } from "../../types/Parent";
import existsInDay from "../../functions/exists-in-day";
import isOverlappingEvent from "../../functions/is-overlapping-event";
import convertStartEndToMinutes from "../../functions/convert-start-end-to-minutes";

interface IProps {
  events: IEvent[];
  onEventSelect: (id: number) => void;
  selectedEvent: number | false;
  owners: IParent[];
  eventPointer: "pointer" | "copy";
}

const days = [0, 1, 2, 3, 4, 5, 6] as Array<0|1|2|3|4|5|6>;
const weeks = [0, 1] as Array<0|1>;

export default function CalendarComponent({ events, onEventSelect, selectedEvent, owners, eventPointer } : IProps) {
  return (
    <div className={`calendar`}>
      {weeks.map(week => (
        <div key={`week_${week}`} className="week">
          {days.map(day => {
            const dayEvents = events.filter(
              nday => {
                const shouldUse = existsInDay(nday, week, day);
                return shouldUse;
              }
            ).map(event => {
              const [startMinutes, endMinutes] = convertStartEndToMinutes(event);
              const endsToday = event.endDay === day && event.endWeek === week;
              const startsToday = event.startDay === day && event.startWeek === week;
              return startMinutes > endMinutes ? startsToday && endsToday ? [{
                ...event,
                startDay: day,
                endDay: day,
                startWeek: week,
                endWeek: week,
                start: "00:00",
                end: endsToday ? event.end : "24:00" 
              },{
                ...event,
                startDay: day,
                endDay: day,
                startWeek: week,
                endWeek: week,
                start: startsToday ? event.start : "00:00",
                end: "24:00" 
              }] : {
                ...event,
                startDay: day,
                endDay: day,
                startWeek: week,
                endWeek: week,
                start: startsToday ? event.start : "00:00",
                end: endsToday ? event.end : "24:00" 
              } : event;
            });

            return (
              <Day
                key={`week_${week}_day_${day}`}
                currentDay={day}
                onEventSelect={onEventSelect}
                selectedEvent={selectedEvent}
                currentWeek={week}
                theme={day === 5 || day === 6 ? 1 : 0}
                owners={owners}
                eventPointer={eventPointer}
                events={dayEvents.flat()}
              />
            );
          })}
        </div>
      ))}
    </div>
  );
};

const getTotalHours = (start: string, end: string) => {
  const startDate = moment(`02-02-2017 ${start}`, "DD-MM-YYYY HH:mm");
  const endDate = moment(`02-02-2017 ${end}`, "DD-MM-YYYY HH:mm");
  const duration = moment.duration(endDate.diff(startDate));
  return duration.asHours();
};

