import moment from "moment";

const shortDay = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

export default (day: number, time: string) => {
  const momentTime = moment(`02-02-2017 ${time}`, "DD-MM-YYYY HH:mm").format(
    "HH:mm"
  );
  return `${shortDay[day]} ${momentTime}`;
};
