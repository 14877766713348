import * as React from "react";
import "./parentSelector.style.css";
import { IParent } from "../../types/Parent";
import { DisplaySmallTitle } from "../Display";
import ToolGroup, { ToolGroupHeader, ToolGroupHeaderButton } from "../ToolGroup";
import styled from "styled-components";

interface IProps {
  onParentToolSelect: (id: string | false) => void;
  parents: IParent[];
  selectedParent: string | false;
  onRemoveParent: (id: any) => void;
  onAddParent: () => void;
  onRenameParent: (id: string, name: string) => void;
  isOpen: boolean;
}

const ParentButton = styled.div(
  ({ isSelected, color }: { isSelected: boolean; color: string }) => `
  width: 32px;
  height: 32px;
  margin: 4px;
  border-radius: 6px;
  box-sizing: border-box;
  border-color: ${color};
  line-height: 24px;
  font-weight: 600;
  text-align: center;
  color: ${isSelected ? "rgb(255,255,255)" : color};
  font-size: 26px;
  cursor: pointer;
  border: solid 2px ${color};
  background-color: ${isSelected ? color : "rgba(0,0,0,0.05)"}
`
);

const ParentSelector = ({
  onParentToolSelect,
  parents,
  selectedParent,
  onRemoveParent,
  onAddParent,
  onRenameParent,
  isOpen,
}: IProps) => {
  const [editing, setEditing] = React.useState<string | boolean>(false);
  const [viewing, setViewing] = React.useState<string | boolean>(false);
  return isOpen ? (
    <>
      <ToolGroup
        header={
          <ToolGroupHeader
            title="Parent"
            buttons={
              <>
                {viewing && (
                  <ToolGroupHeaderButton
                    color="rgb(230, 50, 50)"
                    buttonText="-"
                    onClick={() => onRemoveParent(viewing)}
                  />
                )}
                <ToolGroupHeaderButton color="green" buttonText="+" onClick={onAddParent} />
              </>
            }
          ></ToolGroupHeader>
        }
      >
        <>
          {[...parents].map(parent => (
            <div
              key={`parent_${parent.id}`}
              className={`parentSelector__parent ${viewing === parent.id ? "parentSelector__parent-selected" : ""}`}
              onClick={() => {
                if (editing === parent.id) {
                  return;
                }
                if (viewing === parent.id) {
                  setViewing(false);
                } else {
                  setViewing(parent.id as string | false);
                }
              }}
            >
              {true && (
                <div className={`parentSelector__parent-key`} style={{ backgroundColor: parent.color ?? "blue" }} />
              )}
              {editing === parent.id ? (
                <input
                  onChange={ev => {
                    onRenameParent(parent.id, ev.target.value);
                  }}
                  type="text"
                  className={`parentSelector__input`}
                  value={parent.name}
                ></input>
              ) : (
                <div className={`parentSelector__text`}>{parent.name}</div>
              )}

              {true && (editing === false || editing === parent.id) && (
                <div
                  className={`parentSelector__edit ${editing === parent.id ?? "parentSelector__edit-selected"}`}
                  onClick={e => {
                    setEditing(editing === parent.id ? false : parent.id);
                    e.stopPropagation();
                  }}
                >
                  {editing === parent.id ? "done" : "rename"}
                </div>
              )}
              {true && (
                <div
                  className={`parentSelector__edit ${selectedParent === parent.id ? "parentSelector__active" : ""}`}
                  onClick={e => {
                    onParentToolSelect(parent.id as string | false);
                    e.stopPropagation();
                  }}
                >
                  assign
                </div>
              )}
            </div>
          ))}
        </>
      </ToolGroup>
    </>
  ) : (
    <>
      {parents.map(parent => (
        <ParentButton
          onClick={() => (selectedParent === parent.id ? onParentToolSelect(false) : onParentToolSelect(parent.id))}
          color={parent.color}
          isSelected={selectedParent === parent.id}
          key={`pb+${parent.id}`}
        >
          +
        </ParentButton>
      ))}
    </>
  );
};

export default ParentSelector;
