import { IEvent } from "../types/Event";
import convertEventToId from "./convert-event-to-id";
import sortEvents from "./sort-events";

const START_WEEK = 0;
const START_DAY = 0;
const START_TIME = 0;

export default function getUnplannedTime(events: IEvent[]){

    const sortedEvents = sortEvents(events);

    const firstEvent = sortedEvents.length > 0 && sortedEvents[0].start !== "00:00" ? 
    [{ 
        id: convertEventToId({
            start: "00:00", 
            end: sortedEvents[0].start, 
            startDay: 0, 
            endDay: sortedEvents[0].startDay, 
            startWeek: 0, 
            endWeek: sortedEvents[0].startWeek, 
        }), 
        start: "00:00", 
        end: sortedEvents[0].start, 
        startDay: 0, 
        endDay: sortedEvents[0].startDay, 
        startWeek: 0, 
        endWeek: sortedEvents[0].startWeek, 
        owner: false, 
        isInturruption: false
    }] : [];

    const lastEvent = sortedEvents.length > 0 && sortedEvents[sortedEvents.length - 1].end !== "24:00" ? 
    [{ 
        id: convertEventToId({
            end: "24:00", 
            start: sortedEvents[sortedEvents.length - 1].end, 
            endDay: 6, 
            startDay: sortedEvents[sortedEvents.length - 1].endDay, 
            endWeek: 1, 
            startWeek: sortedEvents[sortedEvents.length - 1].endWeek,
        }), 
        end: "24:00", 
        start: sortedEvents[sortedEvents.length - 1].end, 
        endDay: 6, 
        startDay: sortedEvents[sortedEvents.length - 1].endDay, 
        endWeek: 1, 
        startWeek: sortedEvents[sortedEvents.length - 1].endWeek, 
        owner: false, 
        isInturruption: false
    }] : [];

    const betweenEvents = sortedEvents.map((event: IEvent, index: number) => {
        const nextEvent = index + 1 <= sortedEvents.length - 1 ? sortedEvents[index + 1] : undefined;
        if (nextEvent === undefined) return undefined;
        const times = {
            start: event.end, 
            end: nextEvent.start, 
            startDay: event.endDay, 
            endDay: nextEvent.startDay, 
            startWeek: event.endWeek, 
            endWeek: nextEvent.startWeek, 
        }
        return {
            id: convertEventToId(times),
            ...times,
            owner: false, 
            isInturruption: false
        }
    }).filter(event => event !== undefined);

    const mergedFirstAndLastEvents = [...firstEvent, ...lastEvent].length === 2 ? [{
        start: lastEvent[0].start, 
        end: firstEvent[0].end, 
        startDay: lastEvent[0].startDay, 
        endDay: firstEvent[0].endDay, 
        startWeek: lastEvent[0].startWeek, 
        endWeek: firstEvent[0].endWeek,
        id: convertEventToId({
            start: lastEvent[0].start, 
        end: firstEvent[0].end, 
        startDay: lastEvent[0].startDay, 
        endDay: firstEvent[0].endDay, 
        startWeek: lastEvent[0].startWeek, 
        endWeek: firstEvent[0].endWeek,
        }) + 32,
        owner: false, 
            isInturruption: false
    }] : [...firstEvent, ...lastEvent]

    // console.log("unplanned time", [...mergedFirstAndLastEvents, ...betweenEvents]);


    return [...mergedFirstAndLastEvents, ...betweenEvents] as IEvent[];
}