export default function getParentData(parentJsonString: string | null) {
  const parentsBasicArray = parentJsonString ? JSON.parse(parentJsonString as string) : undefined;
  return parentsBasicArray
    ? parentsBasicArray.map((parent: [string, string, string, number, number], index: number) => ({
        id: parent[0] ?? `${index}`,
        name: parent[1] ?? `Parent ${index}`,
        color: parent[2],
        isParent: parent[3] === 1 ? true : false,
        ignore: parent[4] === 1 ? true : false,
      }))
    : [];
}
