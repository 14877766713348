import * as React from "react";
import styled from "styled-components";

interface DisplayDataProps {
    label: string;
    data: string;
}

const DisplayDataContainer = styled.div`
display: flex;
width: 100%;
`;
const DisplayDataLabel = styled.div`
font-weight: 600;
flex 1;
padding: 5px;
text-align: right;
background-color: rgba(0,0,0,0.05);
`;
const DisplayDataData = styled.div`
font-weight: 400;
text-align: left;
padding: 5px;
flex: 1;
`;

export default function DisplayDataComponent({label, data}: DisplayDataProps){
  return (
    <DisplayDataContainer>
        <DisplayDataLabel>{label}</DisplayDataLabel>
        <DisplayDataData>{data}</DisplayDataData>
    </DisplayDataContainer>
  );
};
