import * as React from "react";
import styled from "styled-components";
import sortEvents from "../../functions/sort-events";
import { IEvent } from "../../types/Event";
import { IParent } from "../../types/Parent";
import { SimpleListItem, ListGroup } from "../List";
import ToolGroup, { ToolGroupHeader, ToolGroupHeaderButton } from "../ToolGroup";
import SchoolForm from "./SchoolForm";

interface SchoolSelectorProps {
    inturruptionEvents: IEvent[];
    onInturruptionEventsChange: (newEvents: IEvent[]) => void;
    owners: IParent[];
    events: IEvent[];
}

const SchoolSelectorContainer = styled.div`
    width: 300px;
    border: 1px solid rgb(220,220,220);
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 20px;
`;

const SchoolSelectorHeaderContainer = styled.div`
width: 100%;
background-color: rgba(0,0,0,0.02);
border-bottom: 1px solid rgba(0,0,0,0.05);
padding: 5px 5px 5px 10px;
box-sizing: border-box;
text-align: left;
display: flex;
`;

const SchoolSelectorHeader = styled.div`

font-weight: 800;
    text-align: left;
    flex: 1;
`;

const dayMap = {
    0: "Mon",
    1: "Tue",
    2: "Wed",
    3: "Thur",
    4: "Fri",
    5: "Sat",
    6: "Sun",
}


export default function SchoolSelectorComponent({inturruptionEvents, onInturruptionEventsChange, events}: SchoolSelectorProps){
    const [isCreating, setIsCreating] = React.useState(false);
    const [selectedEvent, setSelectedEvent] = React.useState(undefined as undefined | number);
    const schoolEvents = inturruptionEvents.filter(event => event.owner === "school");
    const week1Events = schoolEvents.filter(event => event.startWeek === 0);
    const week2Events = schoolEvents.filter(event => event.startWeek === 1);
    
  return (
      <>
      <ToolGroup header={<ToolGroupHeader title="School / Activities" buttons={<>
      {selectedEvent && <ToolGroupHeaderButton color="rgb(230, 50, 50)" buttonText="-" onClick={() => {onInturruptionEventsChange(sortEvents([...inturruptionEvents].filter( event => event.id !== selectedEvent)))}} />}
      <ToolGroupHeaderButton color="green" buttonText="+" onClick={() => {setIsCreating(true)}} />
      </>} />}>
          <>
          {isCreating && <SchoolForm events={events} onCancelClick={() => {setIsCreating(false)}} onSubmitClick={(newEvent: IEvent) => {onInturruptionEventsChange(sortEvents([...inturruptionEvents, newEvent])); setIsCreating(false)}} />}
          {week1Events.length > 0 && <ListGroup text="Week 1" />}
        {
            week1Events.map(event => 
                <SimpleListItem key={`week1_${event.id}`} onClick={() => setSelectedEvent(event.id === selectedEvent ? undefined : event.id)} selected={event.id === selectedEvent} title={`${dayMap[event.startDay]} ${event.start}-${event.end}`} ><></></SimpleListItem>
            )
        }
        {week2Events.length > 0 && <ListGroup text="Week 2" />}
        {
            week2Events.map(event => 
                <SimpleListItem key={`week2_${event.id}`} onClick={() => setSelectedEvent(event.id === selectedEvent ? undefined : event.id)} selected={event.id === selectedEvent} title={`${dayMap[event.startDay]} ${event.start}-${event.end}`} ><></></SimpleListItem>
            )
        }
        </>
      </ToolGroup>
    </>
  );
};
