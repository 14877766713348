import { IEvent } from "../types/Event";

export default function convertEventToId(event: Omit<IEvent, "id" | "isInturruption" | "owner"> | IEvent){
    return (event.startWeek * 1000000) 
        + (event.startDay * 10000) 
        + (parseInt(event.start.split(":")[0]) * 100) 
        + (parseInt(event.start.split(":")[1]) * 1)
        + (event.endWeek * 1000000) 
        + (event.endDay * 10000) 
        + (parseInt(event.end.split(":")[0]) * 100) 
        + (parseInt(event.end.split(":")[1]) * 1)
}