import * as React from "react";
import styled from "styled-components";

interface ButtonProps {
    text: string;
    onClick: () => void;
    disabled?: boolean;
}

const ButtonContainer = styled.button`
    font-weight: 600;
    height: 30px;
    border-radius: 4px;
    color: white;
    line-height: 30px;
    padding: 0px 10px;
    margin: 5px 0px 5px 5px;
    background-color: rgb(65, 136, 243);
    cursor: pointer;
    border: none;
    &:hover{
        background-color: rgb(45, 116, 233);
        color: white;
    }
`;

const ButtonDisabledContainer = styled.button`
    font-weight: 600;
    height: 30px;
    border-radius: 4px;
    color: rgb(150,150,150);
    line-height: 30px;
    padding: 0px 10px;
    margin: 5px 0px 5px 5px;
    background-color: rgb(240,240,240);
    border: none;
`;

export default function ButtonComponent({text, onClick, disabled}: ButtonProps){
  return disabled ? <ButtonDisabledContainer>
  {text}
</ButtonDisabledContainer> : (
    <ButtonContainer onClick={onClick}>
        {text}
    </ButtonContainer>
  );
};
