import * as React from "react";
import styled from "styled-components";

interface LabelProps {
  text: string;
}

const LabelContainer = styled.div`
  width: 100%;
  padding: 5px;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 600;
`;

export default function LabelComponent({ text }: LabelProps) {
  return <LabelContainer>{text}</LabelContainer>;
}
