import React from "react";
import logo from "./logo.svg";
import "./App.css";
import Routes from "./pages/.";
import ReactGA from "react-ga4";

const AppComponent: React.FC = () => {
  React.useEffect(() => {
    ReactGA.initialize("G-SMEN9JG1ZS");
    ReactGA.send("pageview");
  }, []);
  return <Routes />;
};

export default AppComponent;
