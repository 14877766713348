import * as React from "react";
import "./index.style.css";
import Calendar from "../../components/Calendar";
import SchoolDays from "../../data/school-days.json";
import { IEvent } from "../../types/Event";
import getUnplannedTime from "../../functions/get-unplanned-time";
import { IParent } from "../../types/Parent";
import SelectedEventOptions from "../../components/SelectedEventOptions";
import SummaryData from "../../components/SummaryData";
import ParentSelector from "../../components/ParentSelector";
import Key, { KeyItem } from "../../components/Key";
import OptionButton from "../../components/OptionButton/OptionButton";
import SchoolSelector from "../../components/SchoolSelector";
import { useNavigate, useSearchParams } from "react-router-dom";
import mergeSimilarEvents from "../../functions/merge-similar-events";

import convertEventToId from "../../functions/convert-event-to-id";
import SwapSelector from "../../components/SwapMoments/SwapSelector";
import getParentsFromUrl from "./functions/get-parents-from-url";
import SummaryTable from "../../components/SummaryTable";
import SideBar from "./components/SideBar";
import ShareButton from "../../components/ShareButton";
import PopupModalComponent from "../../components/PopupModal";
import { ModalBox } from "../../components/Modal";
import { DisplayCopyData } from "../../components/Display";

const schoolDays = SchoolDays.data
  .map((day, index) => ({
    ...day,
    startDay: day.day as 0 | 1 | 2 | 3 | 4 | 5 | 6,
    endDay: day.day as 0 | 1 | 2 | 3 | 4 | 5 | 6,
    startWeek: day.week as 0 | 1,
    endWeek: day.week as 0 | 1,
    id: index,
    isInturruption: true,
    owner: "school",
  }))
  .map(event => ({ ...event, id: convertEventToId(event) }));

const themes = ["rgb(255, 197, 19)", "rgb(0, 104, 161)", "#82BF45", "#F28B30", "#5BC2D9"];

const defaultNoneParents = [
  {
    name: "Swap",
    id: "swap",
    color: "rgb(100,250,100)",
    isParent: false,
    ignore: true,
  },
  {
    name: "School / Activities",
    id: "school",
    color: "rgb(230, 50, 50)",
    isParent: false,
  },
];

const defaultParents = [
  {
    name: "Parent 1",
    id: "1",
    color: themes[0],
    isParent: true,
  },
  {
    name: "Parent 2",
    id: "2",
    color: themes[1],
    isParent: true,
  },
];

export default function OverviewComponent() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isSideBarOpen, setIsSideBarOpen] = React.useState(true);
  const [isShareVisible, setIsShareVisible] = React.useState(false);

  const [events, setEvents] = React.useState(() => {
    const eventsBasicArray =
      searchParams && searchParams.get("events") ? JSON.parse(searchParams.get("events") as string) : undefined;
    const allEventsFromURL = eventsBasicArray
      ? eventsBasicArray.map((event: [number, string, string, string, string, string, number, string, string]) => ({
          id: event[0],
          startDay: event[1],
          endDay: event[2],
          startWeek: event[3],
          endWeek: event[4],
          owner: event[5],
          isInturruption: event[6] === 1 ? true : false,
          start: event[7],
          end: event[8],
        }))
      : undefined;
    return (allEventsFromURL ?? []) as IEvent[];
  });

  const [parents, setParents] = React.useState(() => {
    const parentsFromUrl = getParentsFromUrl(searchParams && searchParams.get("parents"));
    return parentsFromUrl.length > 0
      ? ([...defaultNoneParents, ...parentsFromUrl] as IParent[])
      : ([...defaultNoneParents, ...defaultParents] as IParent[]);
  });

  const [inturruptionEvents, setInturruptionEvents] = React.useState(() => {
    const eventsBasicArray =
      searchParams && searchParams.get("events") ? JSON.parse(searchParams.get("events") as string) : undefined;
    const interuptionEventsFromURL = eventsBasicArray
      ? eventsBasicArray
          .map((event: [number, string, string, string, string, string, number, string, string]) => ({
            id: event[0],
            startDay: event[1],
            endDay: event[2],
            startWeek: event[3],
            endWeek: event[4],
            owner: event[5],
            isInturruption: event[6] === 1 ? true : false,
            start: event[7],
            end: event[8],
          }))
          .filter((event: IEvent) => event.isInturruption)
      : undefined;
    return (interuptionEventsFromURL ?? schoolDays) as IEvent[];
  });

  const [selectedEvent, setSelectedEvent] = React.useState(false as number | false);
  const [selectedParent, setSelectedParent] = React.useState(false as string | false);

  const [tab, setTab] = React.useState("timetable" as string);
  const navigate = useNavigate();

  const [shareLink, setShareLink] = React.useState("");

  // /**
  //  * Update URL
  //  */
  // React.useEffect(() => {
  //   const ownedEventsCompressed = JSON.stringify(
  //     events
  //       .filter(event => event.owner)
  //       .map(event => [
  //         event.id,
  //         event.startDay,
  //         event.endDay,
  //         event.startWeek,
  //         event.endWeek,
  //         event.owner,
  //         event.isInturruption ? 1 : 0,
  //         event.start,
  //         event.end,
  //       ])
  //   );
  //   const parentsCompressed = JSON.stringify(
  //     parents
  //       .filter(parent => parent.isParent)
  //       .map(parent => [parent.id, parent.name, parent.color, parent.isParent ? 1 : 0, parent.ignore ? 1 : 0])
  //   );
  //   setSearchParams({ ...searchParams, parents: parentsCompressed, events: ownedEventsCompressed });
  // }, [navigate, parents, events, searchParams, setSearchParams]);

  const generateShareLink = () => {
    const ownedEventsCompressed = JSON.stringify(
      events
        .filter(event => event.owner)
        .map(event => [
          event.id,
          event.startDay,
          event.endDay,
          event.startWeek,
          event.endWeek,
          event.owner,
          event.isInturruption ? 1 : 0,
          event.start,
          event.end,
        ])
    );
    const parentsCompressed = JSON.stringify(
      parents
        .filter(parent => parent.isParent)
        .map(parent => [parent.id, parent.name, parent.color, parent.isParent ? 1 : 0, parent.ignore ? 1 : 0])
    );
    setShareLink(
      `https://parentingscheduler.com/?parents=${encodeURIComponent(parentsCompressed)}&events=${encodeURIComponent(
        ownedEventsCompressed
      )}`
    );
    // setSearchParams({ ...searchParams, parents: parentsCompressed, events: ownedEventsCompressed });
  };

  /**
   * Update events when change happens
   */
  React.useEffect(() => {
    const emptyPeriods = getUnplannedTime(inturruptionEvents);

    /**
     * Merge similar events
     */
    const mergedEvents = mergeSimilarEvents(emptyPeriods, events);
    setEvents([...mergedEvents, ...inturruptionEvents]);
  }, [inturruptionEvents]);

  const handleOnEventSelect = (id: number) => {
    if (selectedParent) {
      handleOnParentAssign(selectedParent, id);
      setSelectedEvent(false);
    } else {
      if (selectedEvent === id) {
        setSelectedEvent(false);
      } else {
        setSelectedEvent(id);
      }
    }
  };

  const handleOnParentToolSelected = (id: string | false) => {
    if (id === selectedParent) {
      setSelectedParent(false);
    } else {
      setSelectedParent(id);
    }
    if (!id) {
      setSelectedEvent(false);
    }
  };

  const handleOnParentAssign = (id: string, eventId?: number) => {
    const newEventId = eventId || selectedEvent;
    const selectedEventObj = events.find(event => event.id === newEventId);

    if (selectedEventObj && selectedEventObj.isInturruption) {
      return;
    }

    const selectedParent = parents.find(parent => parent.id === id);

    setEvents(
      events.map(event =>
        event.id === newEventId
          ? {
              ...event,
              owner: id,
              label: selectedParent?.name,
              color: selectedParent?.color,
            }
          : event
      )
    );
  };

  const handleOnRemoveParent = (id: any) => {
    setSelectedParent(false);
    setParents(parents.filter(parent => parent.id !== id));
  };

  const handleOnAddParent = () => {
    if (parents.length - 2 > 4) return;
    const availableColors = themes.filter(color => !parents.map(parent => parent.color).includes(color));
    setParents([
      ...parents,
      {
        name: `Parent ${parents.length + 1}`,
        id: `${parents.length + 1}_${Math.random()}`,
        color: availableColors[0],
        isParent: true,
      },
    ]);
  };

  const handleOnRenameParent = (id: string, name: string) => {
    setParents(parents.map(parent => (parent.id === id ? { ...parent, name } : parent)));
  };

  return (
    <div className={`overview`}>
      <div className="overview__container">
        <SideBar
          onToggleOpen={() => {
            setIsSideBarOpen(!isSideBarOpen);
          }}
          isOpen={isSideBarOpen}
          title="Parenting scheduler"
        >
          <>
            <ParentSelector
              isOpen={isSideBarOpen}
              selectedParent={selectedParent}
              onParentToolSelect={handleOnParentToolSelected}
              onRemoveParent={handleOnRemoveParent}
              onAddParent={handleOnAddParent}
              onRenameParent={handleOnRenameParent}
              parents={parents.filter(owner => owner.isParent)}
            />
            {isSideBarOpen && (
              <>
                <SwapSelector
                  inturruptionEvents={inturruptionEvents}
                  onInturruptionEventsChange={setInturruptionEvents}
                  owners={parents}
                  events={events}
                />
                <SchoolSelector
                  inturruptionEvents={inturruptionEvents}
                  onInturruptionEventsChange={setInturruptionEvents}
                  owners={parents}
                  events={events}
                />
              </>
            )}
          </>
        </SideBar>

        <div
          className="overview__main"
          onClick={e => {
            setSelectedEvent(false);
          }}
        >
          <div className="overview__buttons">
            <OptionButton
              value={tab}
              onPress={setTab}
              options={[
                { id: "timetable", label: "Timetable" },
                { id: "stats", label: "Stats" },
              ]}
            />
          </div>
          <>
            {tab === "timetable" ? (
              <div style={{ display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "center" }}>
                <Calendar
                  events={events}
                  owners={parents}
                  onEventSelect={handleOnEventSelect}
                  selectedEvent={selectedEvent}
                  eventPointer={selectedParent ? "copy" : "pointer"}
                />
                <Key>
                  {parents
                    .filter(owner => owner.ignore !== true)
                    .map(parent => (
                      <KeyItem key={parent.id} title={`${parent.name}`} color={parent.color} />
                    ))}
                </Key>
                {selectedEvent && (
                  <SelectedEventOptions
                    owners={parents}
                    onParentSelect={handleOnParentAssign}
                    selectedEvent={selectedEvent ? events.find(event => event.id === selectedEvent) : undefined}
                  />
                )}
              </div>
            ) : (
              <>
                <SummaryData parents={parents} events={events} />
                <SummaryTable owners={parents} events={events} />
              </>
            )}
          </>
        </div>
      </div>
      <ShareButton
        text="Share schedule"
        onClick={() => {
          generateShareLink();
          setIsShareVisible(true);
        }}
      />
      <div className="buyCoffee">
        <a href="https://www.buymeacoffee.com/dcvannd" target="_blank" rel="noreferrer">
          <img
            src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png"
            alt="Buy Me A Coffee"
            style={{ height: "calc(60px * 0.7)", width: "calc(217px * 0.7)" }}
          />
        </a>
      </div>
      <PopupModalComponent onBackgroundClick={() => setIsShareVisible(false)} isVisible={isShareVisible}>
        <ModalBox title="Share schedule" onCloseClick={() => setIsShareVisible(false)}>
          <DisplayCopyData data={shareLink} label="Share as URL"></DisplayCopyData>
        </ModalBox>
      </PopupModalComponent>
    </div>
  );
}
