import * as React from "react";
import { FieldRenderProps } from "react-final-form";
import styled from "styled-components";
import OptionButton, { IOptionButton } from "../OptionButton/OptionButton";

interface OptionInputProps extends FieldRenderProps<string> {
    options: IOptionButton[]
}

const OptionInputContainer = styled.div`
width: 100%;
display: flex;
justify-content: center;
`;

export default function OptionInputComponent({input, options}: OptionInputProps){
  return (
    <OptionInputContainer>
        <OptionButton options={options} value={input.value} onPress={(value) => {input.onChange(value)}}/>
    </OptionInputContainer>
  );
};
