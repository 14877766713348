import * as React from "react";
import styled from "styled-components";

interface ToolGroupHeaderProps {
  title: string;
  buttons?: React.ReactElement;
}

const ToolGroupHeaderContainer = styled.div`
  width: 100%;
  background-color: rgba(0, 0, 0, 0.02);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding: 5px 5px 5px 10px;
  box-sizing: border-box;
  text-align: left;
  display: flex;
`;
const ToolGroupTextContainer = styled.div`
  font-weight: 800;
  text-align: left;
  flex: 1;
`;
const ToolGroupButtonContainer = styled.div`
  display: flex;
`;

export default function ToolGroupHeaderComponent({ title, buttons }: ToolGroupHeaderProps) {
  return (
    <ToolGroupHeaderContainer>
      <ToolGroupTextContainer>{title}</ToolGroupTextContainer>
      <ToolGroupButtonContainer>{buttons}</ToolGroupButtonContainer>
    </ToolGroupHeaderContainer>
  );
}
