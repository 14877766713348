import * as React from "react";
import "./day.style.css";
import dimensions from "./dimensions";
import getTotalHours from "../../functions/get-total-hours";
import getStartHour from "../../functions/get-start-hour";
import { IEvent } from "../../types/Event";
import getDay from "../../functions/get-day";
import { IParent } from "../../types/Parent";
import styled from "styled-components";

interface IProps {
  theme?: number;
  events: IEvent[];
  currentDay: number;
  currentWeek: number;
  onEventSelect: (id: number) => void;
  selectedEvent: number | false;
  owners: IParent[];
  eventPointer: "pointer" | "copy";
}

const colors = [
  "rgb(200,200,200)",
  "rgb(219, 7, 0)",
  "rgb(255, 197, 19)",
  "rgb(0, 104, 161)",
  "rgb(0,32,203)"
];

const themePalette = ["rgba(255,255,255,0)", "rgba(20,20,20,0.05)"];

const eventBorderRadius = "10px";
const selectedEventStyle = {
  // borderWidth: "3px",
  // borderStyle: "solid",
  // borderColor: "rgba(0,0,0,0.2)",
  // opacity: 1
};

const DayEventContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100px;
`



const Day = (props: IProps) => {
  const {
    theme = 0,
    events,
    currentDay,
    currentWeek,
    onEventSelect,
    selectedEvent,
    owners,
    eventPointer
  } = props;


  

  const lastsAllDay = (day: IEvent) => {
    return (
    (
      day.endDay > currentDay &&
      day.startDay < currentDay &&
      day.startWeek === currentWeek &&
      day.endWeek === currentWeek
    ) 
    || 
    (
      day.startWeek === currentWeek &&
      day.endWeek !== currentWeek &&
      day.startDay !== currentDay
    )
    ||
    (
      day.startWeek !== currentWeek &&
      day.endWeek === currentWeek &&
      day.endDay !== currentDay
    )
  )}

  const endsToday = (day: IEvent) => (
    (
      day.startDay < currentDay &&
      day.startWeek === currentWeek &&
      day.endWeek === currentWeek
    )
    ||
    (
      day.startWeek !== currentWeek &&
      day.endWeek === currentWeek &&
      day.endDay === currentDay
    )
    );

  const startsToday = (day: IEvent) => (
      (
        day.endDay > currentDay &&
        day.startWeek === currentWeek &&
        day.endWeek === currentWeek
      )
      ||
      (
        day.startWeek === currentWeek &&
        day.endWeek !== currentWeek &&
        day.startDay === currentDay
      )
      );

  const processedEvents = events.map(day =>
      lastsAllDay(day)
      ? { ...day, start: "00:00", end: "24:00" }
      : endsToday(day)
      ? { ...day, start: "00:00" }
      : startsToday(day)
      ? { ...day, end: "24:00" }
      :  day
  );
  return (
    <div
      className={`day`}
      style={{
        height: "100px",
        width: `${dimensions.hourWidth * 24}px`,
        backgroundColor: themePalette[theme],
        marginRight: "-1px",
        // marginTop: "10px",
        position: "relative"
      }}
    >
      <div className="day_title">{getDay(currentDay)}</div>
      <DayEventContainer >
      {processedEvents.map(event => {
        const eventColor = owners.find(owner => owner.id === event.owner)?.color;
        return event.start ? (
          <div
          key={`event_${event.id}`}
            onClick={(ev) => {
              onEventSelect(event.id);
              ev.stopPropagation();
            }}
            className={`event ${selectedEvent === event.id ? "day__event-selected" : ""}`}
            style={{
              backgroundColor: eventColor ?? colors[0],
              borderTopLeftRadius:
                event.start === "00:00" ? 0 : eventBorderRadius,
              borderBottomLeftRadius:
                event.start === "00:00" ? 0 : eventBorderRadius,
              borderTopRightRadius:
                event.end === "24:00" ? 0 : eventBorderRadius,
              borderBottomRightRadius:
                event.end === "24:00" ? 0 : eventBorderRadius,
              height: "70px",
              opacity: 1,
              position: "absolute",
              width: `${dimensions.hourWidth *
                getTotalHours(event.start, event.end)}px`,
              left: `${getStartHour(event.start) * dimensions.hourWidth}px`,
              ...(selectedEvent === event.id ? selectedEventStyle : {}),
              cursor: eventPointer
            }}
          ></div>
        ) : (
          <div key={`event_${event.id}`}></div>
        )}
      )}
      </DayEventContainer>
    </div>
  );
};

export default Day;
