import * as React from "react";
import styled from "styled-components";

interface SimpleListItemProps {
    children: React.ReactElement;
    title: string;
    onClick: () => void;
    selected?: boolean;
}

const SimpleListItemContainer = styled.div`
width: 100%;
cursor: pointer;
`;

const SimpleHeaderContainer = styled.div`
padding: 10px;
text-align: left;
border-bottom: 1px solid rgba(0,0,0,0.05);
`;

export default function SimpleListItemComponent({ title, onClick, selected}: SimpleListItemProps){
  return (
    <SimpleListItemContainer onClick={onClick} style={selected ? {backgroundColor: "rgba(0,0,0,0.1)"} : { backgroundColor: "white"}}>
        <SimpleHeaderContainer>{title}</SimpleHeaderContainer>
    </SimpleListItemContainer>
  );
};
