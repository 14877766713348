import * as React from "react";
import styled from "styled-components";

interface ChartKeyProps {
    label: string;
    color: string;
}

const KEY_HEIGHT = 30;

const ChartKeyContainer = styled.div`
display: flex;
`

const ChartKeyColor = styled.div`
width: ${KEY_HEIGHT}px;
height: ${KEY_HEIGHT}px;
border-radius: 8px;
`

const ChartLabel = styled.div`
height: ${KEY_HEIGHT}px;
line-height: ${KEY_HEIGHT}px;
padding-left: 8px;
`

function ChartKeyComponent ({label, color}: ChartKeyProps) {
  return (
    <ChartKeyContainer>
        <ChartKeyColor style={{backgroundColor: color}} />
        <ChartLabel>{label}</ChartLabel>
    </ChartKeyContainer>
  );
};

export default ChartKeyComponent;
