import * as React from "react";
import { BrowserRouter as Router,
    Routes,
    Route,
    Link } from "react-router-dom";
import styled from "styled-components";
import Overview from "./Overview";

interface RouterProps {
}

const indexContainer = styled.div`
`;

export default function RoutesComponents({}: RouterProps){
  return (
    <Router>
        <Routes>
          <Route path="/" element={<Overview />}/>
        </Routes>
    </Router>
  );
};
