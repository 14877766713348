import * as React from "react";
import styled from "styled-components";

interface TextInputProps {
    input: {
        value: string;
        onChange: (changeObj: any) => void;
    };
    placeholder?: string;
    inputType?: "password" | "text";
}

const TextInputContainer = styled.input`
    // height: 30px;
    padding: 5px 10px;
    font-size:medium;
    border-radius: 6px;
    outline: none !important;
    border: none;
    background-color: rgba(0,0,0,0.05);
`;

export default function TextInputComponent({  input: { value, onChange },
    placeholder,
    inputType}: TextInputProps){
  return (
        <TextInputContainer
      type={inputType ?? "text"}
      onChange={onChange}
      value={value}
      placeholder={placeholder}
    ></TextInputContainer>
  );
};
