import * as React from "react";
import styled from "styled-components";

interface ToolGroupProps {
    children: React.ReactElement;
    header?: React.ReactElement;
}

const ToolGroupContainer = styled.div`
    width: 300px;
    border: 1px solid rgb(220,220,220);
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 20px;
`;

export default function ToolGroupComponent({header, children}: ToolGroupProps){
  return (
    <ToolGroupContainer>
        {header}
        {children}
    </ToolGroupContainer>
  );
};
