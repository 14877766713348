import * as React from "react";
import "./key.style.css";

interface IProps {
  children: any;
}

const KeyContainer = (props: IProps) => {
  const { children } = props;
  return <div className={`keyContainer`}>{children}</div>;
};

export default KeyContainer;
