import * as React from "react";
import "./selectedEventOptions.style.css";
import { IEvent } from "../../types/Event";
import { IParent } from "../../types/Parent";
import { DisplayInfoBox, DisplaySmallTitle } from "../Display";
import getDayTime from "../../functions/get-day-time";
import getTotalHours from "../../functions/get-total-hours";
import getTotalHoursExtended from "../../functions/get-total-hours-extended";

interface IProps {
  selectedEvent?: IEvent;
  owners: IParent[];
  onParentSelect: (id: string) => void;
}

const SelectedEventOptions = (props: IProps) => {
  const { selectedEvent, owners, onParentSelect } = props;
  const exists = selectedEvent;
  const isInturruption = selectedEvent && selectedEvent.isInturruption;
  const owner = selectedEvent && selectedEvent.owner;
  // const label = selectedEvent && selectedEvent.label;
  const label = selectedEvent && owners.find(owner => owner.id === selectedEvent.owner)?.name;

  return (
    <div className={`selectedEventOptions`}>
      <div className={`selectedEventOptions__title-container`}>Selection</div>
      {selectedEvent ?
      <>
      <div className="selectedEventOptions__label">{label ?? "Not assigned"}</div>
      <div className="selectedEventOptions__data-container">
        <div className="selectedEventOptions__data-label">From:</div>
        <div className="selectedEventOptions__data">{getDayTime(
          selectedEvent.startDay,
          selectedEvent.start
        )}</div>
      </div>
      <div className="selectedEventOptions__data-container">
        <div className="selectedEventOptions__data-label">To:</div>
        <div className="selectedEventOptions__data">{getDayTime(selectedEvent.endDay, selectedEvent.end)}</div>
      </div>
      <div className="selectedEventOptions__data-container">
        <div className="selectedEventOptions__data-label">Hours:</div>
        <div className="selectedEventOptions__data">{getTotalHoursExtended(
          selectedEvent.start,
          selectedEvent.end,
          selectedEvent.startDay,
          selectedEvent.endDay,
          selectedEvent.startWeek,
          selectedEvent.endWeek
        )}</div>
      </div></>
      : <>
      <div style={{flex: 1, display: "flex", justifyContent:"center", alignItems: "center", color: "rgba(0,0,0,0.3)"}} >Nothing Selected</div>
    </>}
    </div>)
};

export default SelectedEventOptions;
