import moment from "moment";

export default (start: string, end: string, startDay: number, endDay: number, startWeek: number, endWeek: number) => {
  /**
   * If it goes back to begining, set the end week + 2
   */
  const isOverlapping = startDay + 1 + startWeek * 7 > endDay + 1 + endWeek * 7;

  /**
   * Make calendar compatable days of month
   */
  const newStartDay = String(startDay + 1 + startWeek * 7).padStart(2, "0");
  const newEndDay = String(endDay + 1 + (isOverlapping ? endWeek + 2 : endWeek) * 7).padStart(2, "0"); // If overlapping, add 2 weeks to end

  /**
   * Make calendar compatable days of month
   */
  const startDate = moment(`${newStartDay}-02-2017 ${start}`, "DD-MM-YYYY HH:mm");
  const endDate = moment(`${newEndDay}-02-2017 ${end}`, "DD-MM-YYYY HH:mm");

  /**
   * Get duration
   */
  const duration = moment.duration(endDate.diff(startDate));
  return duration.asHours();
};
