import * as React from "react";
import styled from "styled-components";

interface IProps {
  children: any;
}

const DisplayInfoBoxContainer = styled.div`
  // border-width: 2px;
  // border-style: solid;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: 300px;
  // min-height: 50px;
  margin: 20px auto;
  background-color: white;
`;

const DisplayInfoBox = (props: IProps) => {
  const { children } = props;
  return <DisplayInfoBoxContainer>{children}</DisplayInfoBoxContainer>;
};

export default DisplayInfoBox;
