import { IEvent } from "../types/Event";
import convertEventToId from "./convert-event-to-id";
import convertStartEndToMinutes from "./convert-start-end-to-minutes";

export default function sortEvents(events: IEvent[]) {
  const exception =
    events.filter(event => {
      const [startMinutes, endMinutes] = convertStartEndToMinutes(event);
      return startMinutes > endMinutes;
    }) ?? [];
  return [
    ...exception,
    ...events
      .filter(event => !(exception[0] && exception[0].id === event.id))
      .map(event => ({ ...event, id: convertEventToId(event) }))
      .sort((a, b) => (a.id > b.id ? 1 : b.id > a.id ? -1 : 0)),
  ];
}
