import * as React from "react";
import { IEvent } from "../../types/Event";
import { IParent } from "../../types/Parent";
import { DisplayData, DisplayInfoBox, DisplaySmallTitle } from "../Display";
import { PieChart } from "react-minimal-pie-chart";
import getTotalHoursExtended from "../../functions/get-total-hours-extended";
import styled from "styled-components";
import ChartKey from "./ChartKey";
import { Constants } from "../../constants";

interface IProps {
  parents: IParent[];
  events: IEvent[];
}

interface IParentSummaryData {
  totalEvents: number;
  totalHours: number;
  totalPercent: number;
  name: string;
  theme: number;
}

const SummaryDataContainer = styled.div`
  width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${Constants.media.small} {
    flex-direction: row;
  }
`;

const SummaryDataStatsContainer = styled.div`
  // width: 300px;
  min-height: 370px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${Constants.media.small} {
    align-items: start;
  }
`;

const SummaryDataPieContainer = styled.div`
  width: 300px;
  min-height: 370px;
  display: flex;
  align-items: center;
  margin-right: 20px;
`;

const SummaryData = ({ parents, events }: IProps) => {
  const [onlyParents, setOnlyParents] = React.useState(true);

  const uninturruptedEventHours = React.useMemo(
    () =>
      events
        .filter(event => !event.isInturruption)
        .map(event =>
          getTotalHoursExtended(event.start, event.end, event.startDay, event.endDay, event.startWeek, event.endWeek)
        ),
    [events]
  );

  const ownedEventHours = React.useMemo(
    () =>
      events
        .filter(event => event.owner && !event.isInturruption)
        .map(event =>
          getTotalHoursExtended(event.start, event.end, event.startDay, event.endDay, event.startWeek, event.endWeek)
        ),
    [events]
  );

  const ownedEventTotalHours = ownedEventHours.length > 0 ? ownedEventHours.reduce((a, b) => a + b) : 0;

  const totalChildHours = uninturruptedEventHours.length > 0 ? uninturruptedEventHours.reduce((a, b) => a + b) : 0;

  const parentsData = parents
    .filter(owner => (onlyParents ? owner.isParent : true))
    .map(parent => {
      const ownedEvents = events.filter(event => event.owner === parent.id);
      const totalHoursArray = ownedEvents.map(event =>
        getTotalHoursExtended(event.start, event.end, event.startDay, event.endDay, event.startWeek, event.endWeek)
      );

      const totalHours = totalHoursArray.length !== 0 ? totalHoursArray.reduce((a, b) => a + b) : 0;

      const distributionPercent = totalHours / ownedEventTotalHours;

      return {
        totalEvents: ownedEvents.length,
        totalHours,
        weekPercent: totalHours / totalChildHours,
        totalPercent: isNaN(distributionPercent) ? 0 : distributionPercent,
        averageDaysPerWeek: distributionPercent ? 7 * distributionPercent : 0,
        name: parent.name,
        color: parent.color,
        id: parent.id,
      };
    });
  return (
    <SummaryDataContainer>
      <SummaryDataPieContainer>
        <PieChart
          label={({ dataEntry }) => Math.round(dataEntry.percentage) + "%"}
          // labelPosition={100 - 20 / 3}
          labelStyle={{
            fill: "#fff",
            opacity: 0.75,
            pointerEvents: "none",
            fontSize: "10px",
          }}
          segmentsStyle={{ transition: "stroke .3s", cursor: "pointer" }}
          data={parentsData.map(parent => ({
            title: parent.name,
            value: parseInt((parseFloat(parent.totalPercent.toFixed(2)) * 100).toFixed(0)),
            color: parent.color,
          }))}
        />
      </SummaryDataPieContainer>
      <SummaryDataStatsContainer>
        {parentsData.map(parent => (
          <React.Fragment key={`parent_map_${parent.id}`}>
            <ChartKey label={parent.name} color={parent.color} />
            <DisplayInfoBox>
              <DisplayData label="Total hours:" data={parent.totalHours.toString()} />
              <DisplayData
                label="Total percent:"
                data={`${(parseFloat(parent.totalPercent.toFixed(2)) * 100).toFixed(0)}%`}
              />
              <DisplayData label="Days per week:" data={parent.averageDaysPerWeek.toFixed(2)} />
              <DisplayData label="Total events:" data={parent.totalEvents.toString()} />
            </DisplayInfoBox>
          </React.Fragment>
        ))}
      </SummaryDataStatsContainer>
    </SummaryDataContainer>
  );
};

export default SummaryData;
