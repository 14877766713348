import * as React from "react";
import styled from "styled-components";
import { ToolGroupHeader, ToolGroupHeaderButton } from "../ToolGroup";

interface ModalBoxProps {
  title?: string;
  children?: React.ReactElement;
  onCloseClick?: () => void;
}

const ModalBoxContainer = styled.div`
  min-width: 350px;
  max-width: 500px;
  border: 1px solid rgb(220, 220, 220);
  border-radius: 5px;
  overflow: hidden;
  background-color: white;
  cursor: auto;
`;

const ModalBoxContent = styled.div`
  padding: 10px;
`;

export default function ModalBoxComponent({ title, children, onCloseClick }: ModalBoxProps) {
  return (
    <ModalBoxContainer
      onClick={ev => {
        ev.stopPropagation();
      }}
    >
      {title && (
        <ToolGroupHeader
          title={title}
          buttons={onCloseClick && <ToolGroupHeaderButton onClick={onCloseClick} buttonText="x" />}
        ></ToolGroupHeader>
      )}
      <ModalBoxContent>{children}</ModalBoxContent>
    </ModalBoxContainer>
  );
}
