import * as React from "react";
import styled from "styled-components";
import CopySVG from "../../assets/copy.svg";
import CheckSVG from "../../assets/check.svg";
import { Theme } from "../../constants";
import copy from "copy-to-clipboard";
import Label from "../Form/components/Label";

interface DisplayCopyDataProps {
  data: string;
  label?: string;
}

const INPUT_HEIGHT = "40px";

const DisplayCopyDataContainer = styled.div`
  width: 100%;
  display: flex;
  height: ${INPUT_HEIGHT};
  border: 1px solid rgb(200, 200, 200);
`;

const DisplayCopyDataInput = styled.input`
  height: ${INPUT_HEIGHT};
  flex: 1;
  //   line-height: ${INPUT_HEIGHT};
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  border-radius: 0px;
  padding: 0px 10px;
  border: none;
`;

const DisplayCopyDataButton = styled.button`
  height: ${INPUT_HEIGHT};
  //   width: ${INPUT_HEIGHT};
  border-radius: 0px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 10px;
  font-weight: 600;
  color: white;
`;

export default function DisplayCopyDataComponent({ data, label }: DisplayCopyDataProps) {
  const [isCopied, setIsCopied] = React.useState(false);
  const handleOnCopyDataClick = () => {
    setIsCopied(true);
    copy(data);
    setTimeout(() => setIsCopied(false), 2000);
  };
  return (
    <>
      {label && <Label text={label} />}
      <DisplayCopyDataContainer>
        <DisplayCopyDataInput type="text" value={data}></DisplayCopyDataInput>
        <DisplayCopyDataButton
          style={isCopied ? { backgroundColor: Theme.palette.green } : { backgroundColor: Theme.palette.theme1 }}
          onClick={handleOnCopyDataClick}
        >
          {isCopied ? "Copied" : "Copy"}{" "}
          <img style={{ marginLeft: "5px", color: "white" }} src={isCopied ? CheckSVG : CopySVG} />
        </DisplayCopyDataButton>
      </DisplayCopyDataContainer>
    </>
  );
}
