import * as React from "react";
import styled from "styled-components";
import MenuSVG from "../../../assets/menu.svg";
import BackSVG from "../../../assets/back.svg";

interface SideBarProps {
  title: string;
  isOpen: boolean;
  onToggleOpen: () => void;
  children: React.ReactElement;
}

const HEADER_SIZE = "40px";

const SideBarContainer = styled.div(
  ({ isOpen }: { isOpen: boolean }) => `
  height: -webkit-fill-available;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  top: 0px;
  
  z-index: 100;
  position: relative;
  display: flex;
  flex-direction: column;
  ${isOpen ? "" : `width: ${HEADER_SIZE};`}
`
);

const SideBarContent = styled.div(
  ({ isOpen }: { isOpen: boolean }) => `
  box-sizing: border-box;
  ${isOpen ? "padding: 0px 20px 20px 20px;" : ""}
  overflow-y: scroll;
  height: 100%;
  flex: 1;
`
);

const SideBarTitleContainer = styled.div(
  ({ isOpen }: { isOpen: boolean }) => `
  display: flex;
  width: 100%;
  height: calc(${HEADER_SIZE} * ${isOpen ? "2" : "1.2"});
`
);

const SideBarTitle = styled.h2`
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  flex: 1;
  margin: 20px 0px;
  line-height: ${HEADER_SIZE};
  height: ${HEADER_SIZE};
`;

const SideBarToggleButton = styled.button`
  width: ${HEADER_SIZE};
  height: ${HEADER_SIZE};
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
`;

export default function SideBarComponent({ children, title, onToggleOpen, isOpen }: SideBarProps) {
  return (
    <SideBarContainer isOpen={isOpen}>
      <SideBarTitleContainer isOpen={isOpen}>
        {isOpen && <SideBarTitle>{title}</SideBarTitle>}
        <SideBarToggleButton onClick={onToggleOpen}>
          {!isOpen ? <img src={MenuSVG} /> : <img src={BackSVG} />}
        </SideBarToggleButton>
      </SideBarTitleContainer>
      <SideBarContent isOpen={isOpen}>{children}</SideBarContent>
    </SideBarContainer>
  );
}
