import * as React from "react";

import { Theme } from "../../constants";
import styled from "styled-components";

export interface IOptionButton {
  id: string;
  label: string;
}

interface IProps {
  options?: IOptionButton[];
  value: string;
  onPress?: (id: string) => void;
}
const buttonPadding = 10;
const fontSize = 16;

const ButtonContainer = styled.div`
  border-radius: ${buttonPadding * 2 + fontSize}px;
  position: relative;
  min-width: 50;
  background-color: rgb(255, 255, 255);
  display: flex;
  overflow: hidden;
  border-width: 1px;
  font-size: ${fontSize}px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin: 5px 0px;
`;

const Button = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  padding: ${buttonPadding}px;
  justifycontent: center;
  alignitems: center;
  cursor: pointer;
`;

const OptionButtonComponent = ({ options = [], value, onPress }: IProps) => {
  const handleOnPress = (id: string) => () => {
    onPress && onPress(id);
  };
  return (
    <ButtonContainer>
      {options.map(item => (
        <Button
          key={`option_${item.id}`}
          style={
            item.id === value
              ? {
                  backgroundColor: "rgba(0,0,0,0.1)",
                }
              : {}
          }
          onClick={handleOnPress(item.id)}
        >
          {item.label}
        </Button>
      ))}
    </ButtonContainer>
  );
};

export default OptionButtonComponent;
