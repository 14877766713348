import * as React from "react";
import "./key.style.css";

interface IProps {
  title: string;
  color: string;
}

const KeyItem = (props: IProps) => {
  const { title, color } = props;
  return (
    <div className={`keyItem`}>
      <div className="keyItem_color" style={{ backgroundColor: color }}></div>
      {title}
    </div>
  );
};

export default KeyItem;
