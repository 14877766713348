import * as React from "react";
import styled from "styled-components";

interface ListGroupProps {
    text: string;
}

const ListGroupContainer = styled.div`
    width: 100%;
    background-color: rgba(0,0,0,0.05);
    padding: 5px;
    // border-bottom: 1px solid rgba(0,0,0,0.1);
`;

export default function ListGroupComponent({text}: ListGroupProps){
  return (
    <ListGroupContainer>
        {text}
    </ListGroupContainer>
  );
};
