import * as React from "react";
import styled from "styled-components";

interface ToolGroupHeaderButtonProps {
    buttonText: string;
    onClick: () => void;
    color?: string;
}

const ToolGroupHeaderButtonContainer = styled.div`
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    // background-color: white;
    cursor: pointer;   
`;

export default function ToolGroupHeaderButtonComponent({buttonText, onClick, color = "black"}: ToolGroupHeaderButtonProps){
  return (
    <ToolGroupHeaderButtonContainer style={{color: color}} onClick={onClick}>
        {buttonText}
    </ToolGroupHeaderButtonContainer>
  );
};
