import * as React from "react";
import { FieldRenderProps } from "react-final-form";
import styled from "styled-components";

interface TimeInputProps extends FieldRenderProps<string> {
  label: string;
}

const TimeInputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 15px;
  height: 50px;
  margin: 20px 0px;
`;

const TimeInputLabel = styled.div`
  margin-right: 10px;
  width: 50px;
`;

const TimeInputValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5px;
  flex-spacing: between;
`;

const TimeInputValueButton = styled.div`
  width: 20px;
  height: 20px;
  background-color: rgb(220, 220, 220);
  border-radius: 100%;
  cursor: pointer;
  margin: 8px;
  user-select: none;
  text-align: center;
  &:hover {
    background-color: rgb(200, 200, 200);
  }
`;

const TimeInputValue = styled.div``;

const HOUR_MAX = 23;
const MIN_MAX = 55;

const zeroPad = (num: number, places: number) => String(num).padStart(places, "0");

export default function TimeInputComponent({ label, input }: TimeInputProps) {
  const values = input.value.split(":");
  const handleOnHourHigher = () => {
    if (parseInt(values[0]) + 1 > HOUR_MAX) {
      input.onChange(`00:${values[1]}`);
      return;
    } else {
      input.onChange(`${zeroPad(parseInt(values[0]) + 1, 2)}:${values[1]}`);
      return;
    }
  };
  const handleOnHourLower = () => {
    if (parseInt(values[0]) === 0) {
      input.onChange(`${HOUR_MAX}:${values[1]}`);
      return;
    } else {
      input.onChange(`${zeroPad(parseInt(values[0]) - 1, 2)}:${values[1]}`);
      return;
    }
  };
  const handleOnMinuteHigher = () => {
    if (parseInt(values[1]) + 5 > MIN_MAX) {
      input.onChange(`${values[0]}:00`);
      return;
    } else {
      input.onChange(`${values[0]}:${zeroPad(parseInt(values[1]) + 5, 2)}`);
      return;
    }
  };
  const handleOnMinuteLower = () => {
    if (parseInt(values[1]) === 0) {
      input.onChange(`${values[0]}:${MIN_MAX}`);
      return;
    } else {
      input.onChange(`${values[0]}:${zeroPad(parseInt(values[1]) - 5, 2)}`);
      return;
    }
  };
  return (
    <TimeInputContainer>
      <TimeInputLabel>{label}</TimeInputLabel>
      <TimeInputValueContainer>
        <TimeInputValueButton onClick={handleOnHourHigher}>+</TimeInputValueButton>
        <TimeInputValue>{values[0]}</TimeInputValue>
        <TimeInputValueButton onClick={handleOnHourLower}>-</TimeInputValueButton>
      </TimeInputValueContainer>
      <TimeInputValueContainer>
        <TimeInputValueButton onClick={handleOnMinuteHigher}>+</TimeInputValueButton>
        <TimeInputValue>{values[1]}</TimeInputValue>
        <TimeInputValueButton onClick={handleOnMinuteLower}>-</TimeInputValueButton>
      </TimeInputValueContainer>
    </TimeInputContainer>
  );
}
