import * as React from "react";
import styled from "styled-components";

interface PopupModalProps {
  isVisible: boolean;
  onBackgroundClick: () => void;
  children: React.ReactElement;
}

const PopupModalContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 500;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function PopupModalComponent({ isVisible = false, onBackgroundClick, children }: PopupModalProps) {
  return (
    <PopupModalContainer
      onClick={onBackgroundClick}
      style={{ opacity: isVisible ? 1 : 0, pointerEvents: !isVisible ? "none" : "auto" }}
    >
      {children}
    </PopupModalContainer>
  );
}
