import * as React from "react";
import styled from "styled-components";

interface ShareButtonProps {
  text: string;
  onClick: () => void;
}

const ShareButtonContainer = styled.button`
  position: absolute;
  right: 10px;
  top: 10px;
  font-weight: 600;
  height: 30px;
  border-radius: 4px;
  color: white;
  line-height: 30px;
  padding: 0px 10px;
  margin: 5px 0px 5px 5px;
  background-color: rgb(65, 136, 243);
  cursor: pointer;
  border: none;
  &:hover {
    background-color: rgb(45, 116, 233);
    color: white;
  }
`;

export default function ShareButtonComponent({ text, onClick }: ShareButtonProps) {
  return <ShareButtonContainer onClick={onClick}>{text}</ShareButtonContainer>;
}
