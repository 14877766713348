import * as React from "react";
import { Field, Form } from "react-final-form";
import styled from "styled-components";
import cantAddToEvents from "../../functions/cant-add-to-events";
import convertEventToId from "../../functions/convert-event-to-id";
import { IEvent } from "../../types/Event";
import Button from "../Button";
import ButtonText from "../ButtonText";
import { OptionInput, TextInput, TimeInput } from "../Form";

interface SchoolFormProps {
  onCancelClick: () => void;
  onSubmitClick: (event: IEvent) => void;
  events: IEvent[];
}

const SchoolFormContainer = styled.div`
  min-height: 200px;
  width: 100%;
  padding: 10px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default function SchoolFormComponent({ onSubmitClick, onCancelClick, events }: SchoolFormProps) {
  return (
    <>
      <Form
        initialValues={
          {
            isInturruption: true,
            owner: "swap",
            startWeek: 0,
            startDay: 0,
            start: "08:00",
            end: "08:00",
          } as Partial<IEvent>
        }
        onSubmit={(values: IEvent) => {
          onSubmitClick({
            ...values,
            id: convertEventToId({
              ...values,
              id: Math.random(),
              end: values.start,
              endDay: values.startDay,
              endWeek: values.startWeek,
            }),
            endDay: values.startDay,
            endWeek: values.startWeek,
            end: values.start,
          });
        }}
      >
        {({ values, handleSubmit }) => {
          const invalidEventTime = cantAddToEvents(
            { ...values, end: values.start, endDay: values.startDay, endWeek: values.startWeek },
            events
          );
          return (
            <SchoolFormContainer>
              <Field
                name="startWeek"
                component={OptionInput}
                options={[
                  { id: 0, label: "Week 1" },
                  { id: 1, label: "Week 2" },
                ]}
              />
              <Field
                name="startDay"
                component={OptionInput}
                options={[
                  { id: 0, label: "Mo" },
                  { id: 1, label: "Tu" },
                  { id: 2, label: "We" },
                  { id: 3, label: "Th" },
                  { id: 4, label: "Fr" },
                  { id: 5, label: "Sa" },
                  { id: 6, label: "Su" },
                ]}
              />
              <Field name="start" label="Time:" component={TimeInput} />
              {invalidEventTime !== false && (
                <div
                  style={{
                    height: 40,
                    width: "100%",
                    textAlign: "center",
                    lineHeight: "40px",
                    color: "rgb(230, 50, 50)",
                  }}
                >
                  Can't overlap with other activities
                </div>
              )}
              <div>
                <ButtonText text="Cancel" onClick={onCancelClick} />
                <Button text="Add" disabled={invalidEventTime !== false} onClick={handleSubmit} />
              </div>
            </SchoolFormContainer>
          );
        }}
      </Form>
    </>
  );
}
