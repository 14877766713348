import * as React from "react";
import styled from "styled-components";
import getTotalHours from "../../functions/get-total-hours";
import getTotalHoursExtended from "../../functions/get-total-hours-extended";
import sortEvents from "../../functions/sort-events";
import { IEvent } from "../../types/Event";
import { IParent } from "../../types/Parent";

interface SummaryTableProps {
  events: IEvent[];
  owners: IParent[];
}

const SummaryTableContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 50px 0px 100px 0px;
`;

const SummaryTableTable = styled.table`
  border: 1px solid rgb(220, 220, 220);
  border-radius: 5px;
  background-color: white;
`;

const SummaryTableRow = styled.tr``;

const SummaryTableHeader = styled.th`
  text-align: left;
  padding: 10px;
`;

const SummaryTableData = styled.td`
  text-align: left;
  padding: 10px;
`;

const days = {
  0: "Mo",
  1: "Tu",
  2: "We",
  3: "Th",
  4: "Fr",
  5: "Sa",
  6: "Su",
};

export default function SummaryTableComponent({ events, owners = [] }: SummaryTableProps) {
  const sortedEvents = sortEvents(events).filter(event => !event.isInturruption);

  return (
    <SummaryTableContainer>
      <SummaryTableTable>
        <SummaryTableRow style={{ backgroundColor: "rgba(0,0,0,0.1)" }}>
          <SummaryTableHeader></SummaryTableHeader>
          <SummaryTableHeader>Person</SummaryTableHeader>
          <SummaryTableHeader>Start</SummaryTableHeader>
          <SummaryTableHeader>Finish</SummaryTableHeader>
          <SummaryTableHeader>Hours</SummaryTableHeader>
        </SummaryTableRow>
        {sortedEvents.map(event => (
          <SummaryTableRow key={event.id} style={{ backgroundColor: event.isInturruption ? "rgba(0,0,0,0.05)" : "" }}>
            <SummaryTableData
              style={{
                width: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "15px",
                  height: "15px",
                  borderRadius: "4px",
                  backgroundColor: owners.find(owner => owner.id === event.owner)?.color ?? "",
                }}
              ></div>
            </SummaryTableData>
            <SummaryTableData>
              {owners.find(owner => event.owner === owner.id)?.name ?? "Not assigned"}
            </SummaryTableData>
            <SummaryTableData>{`Wk${event.startWeek + 1} ${days[event.startDay]} ${event.start}`}</SummaryTableData>
            <SummaryTableData>{`Wk${event.endWeek + 1} ${days[event.endDay]} ${event.end}`}</SummaryTableData>
            <SummaryTableData>
              {getTotalHoursExtended(
                event.start,
                event.end,
                event.startDay,
                event.endDay,
                event.startWeek,
                event.endWeek
              )}
            </SummaryTableData>
          </SummaryTableRow>
        ))}
      </SummaryTableTable>
    </SummaryTableContainer>
  );
}
