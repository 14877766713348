import { IEvent } from "../types/Event";

const TWO_WEEKS_IN_MINUTES = 20160;

export default function cantAddToEvents(newEvent: IEvent | Omit<IEvent, "id" | "isInturruption" | "owner">, events: IEvent[]){
    const overlappingEvents = events.filter(event => {
        let eventStartMinutes = ((event.startDay + (event.startWeek * 7)) * (24 * 60)) + (parseInt(event.start.split(":")[0]) * 60) + (parseInt(event.start.split(":")[1]));
        let eventEndMinutes = ((event.endDay + (event.endWeek * 7)) * (24 * 60)) + (parseInt(event.end.split(":")[0]) * 60) + (parseInt(event.end.split(":")[1]));

        let newEventStartMinutes = ((newEvent.startDay + (newEvent.startWeek * 7)) * (24 * 60)) + (parseInt(newEvent.start.split(":")[0]) * 60) + (parseInt(newEvent.start.split(":")[1]));
        let newEventEndMinutes = ((newEvent.endDay + (newEvent.endWeek * 7)) * (24 * 60)) + (parseInt(newEvent.end.split(":")[0]) * 60) + (parseInt(newEvent.end.split(":")[1]));

        if(eventStartMinutes > eventEndMinutes){
            eventEndMinutes += TWO_WEEKS_IN_MINUTES;
            newEventStartMinutes += TWO_WEEKS_IN_MINUTES;
            newEventEndMinutes += TWO_WEEKS_IN_MINUTES;
        }

        const isStartInPeriod = (eventStartMinutes < newEventStartMinutes && eventEndMinutes > newEventStartMinutes) || (newEventStartMinutes < eventStartMinutes && newEventEndMinutes > eventStartMinutes);
        const isEndInPeriod = (eventStartMinutes < newEventEndMinutes && eventEndMinutes > newEventEndMinutes) || (newEventStartMinutes < eventEndMinutes && newEventEndMinutes > eventEndMinutes);

        return isStartInPeriod 
        || isEndInPeriod 
        || (eventStartMinutes === newEventStartMinutes && (eventEndMinutes === newEventEndMinutes || eventEndMinutes < newEventEndMinutes)) 
        || (eventEndMinutes === newEventEndMinutes && (eventStartMinutes === newEventStartMinutes || eventStartMinutes > newEventStartMinutes));
    })
    return overlappingEvents.filter(event => event.isInturruption).length > 0 ? overlappingEvents[0]: false;
}