import { IEvent } from "../types/Event";

const TWO_WEEKS_IN_MINUTES = 20160;
const ONE_WEEK_IN_MINUTES = TWO_WEEKS_IN_MINUTES / 2;

export default function existsInDay(event: IEvent, week: 0|1, day: 0|1|2|3|4|5|6){
        let eventStartMinutes = ((event.startDay + (event.startWeek * 7)) * (24 * 60)) + (parseInt(event.start.split(":")[0]) * 60) + (parseInt(event.start.split(":")[1]));
        let eventEndMinutes = ((event.endDay + (event.endWeek * 7)) * (24 * 60)) + (parseInt(event.end.split(":")[0]) * 60) + (parseInt(event.end.split(":")[1]));

        let newEventStartMinutes = ((day + (week * 7)) * (24 * 60)) + (parseInt("00") * 60) + (parseInt("00"));
        let newEventEndMinutes = ((day + (week * 7)) * (24 * 60)) + (parseInt("24") * 60) + (parseInt("00"));

        if(eventStartMinutes > eventEndMinutes){
            if((newEventStartMinutes > eventEndMinutes)){
                eventEndMinutes += TWO_WEEKS_IN_MINUTES;
            }
            else{
                eventEndMinutes += TWO_WEEKS_IN_MINUTES;
                newEventStartMinutes += TWO_WEEKS_IN_MINUTES;
                newEventEndMinutes += TWO_WEEKS_IN_MINUTES;
            }
            
            
        }

        

        const isStartInPeriod = (eventStartMinutes < newEventStartMinutes && eventEndMinutes > newEventStartMinutes) || (newEventStartMinutes < eventStartMinutes && newEventEndMinutes > eventStartMinutes);
        const isEndInPeriod = (eventStartMinutes < newEventEndMinutes && eventEndMinutes > newEventEndMinutes) || (newEventStartMinutes < eventEndMinutes && newEventEndMinutes > eventEndMinutes);
        
        return isStartInPeriod 
        || isEndInPeriod 
        || (eventStartMinutes === newEventStartMinutes && (eventEndMinutes === newEventEndMinutes || eventEndMinutes < newEventEndMinutes)) 
        || (eventEndMinutes === newEventEndMinutes && (eventStartMinutes === newEventStartMinutes || eventStartMinutes > newEventStartMinutes));
}