const size = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptopL: "1440px",
  desktop: "2560px",
};

export const Theme = {
  palette: {
    theme1: "rgb(65,136,243)",
    theme2: "#00d2d4",
    theme3: "#254765",
    theme4: "#63a1f2",
    theme5: "#80b2f2",
    green: "#02A676",
  },
};

export const Constants = {
  media: {
    small: `(min-width: ${size.tablet})`,
    medium: `(min-width: ${size.laptop})`,
    large: `(min-width: ${size.desktop})`,
    theme: Theme,
  },
};
